import React from "react";

// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    Dialog,
    DialogContent,
    Switch,
    TextField,
    DialogActions,
    FormControl,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
export const config = require("./config");
import { Helpcircle, edit, tick,cancel } from "./assets"
const CustomTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
      borderRadius:"10px",
      borderColor:"#E6E6E6",
    }
  })
export const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#364BA0',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#364BA0',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))((props: any) => {
    const { classes, ...otherProps } = props;
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...otherProps}
      />
    );
  });
  const CustomDialog = styled(Dialog)({
    '&.radius-class > div > div': {
      borderRadius: "19px",
      width:"480px"

    }
  })
  const PasswordDialog = styled(Dialog)({
    '&.radius-class > div > div': {
      borderRadius: "18px",
    }
  })

  const HeaderGrid = styled(Grid)({
    '@media(max-width: 599px)': {
        '& .inner-header-box': {
            maxWidth: '100%',
            margin: 'auto',
            marginTop:"14px"
        },
    },
    '@media(max-width: 480px)': {
        '& .inner-header-box': {
            maxWidth: '100%',
            margin: 'auto',
            marginTop:"14px"
        },
    }
});
import { image_Success } from "../../email-account-registration/src/assets";
// Customizable Area End

import FreelancerPasswordSettingController, { Props } from "./FreelancerPasswordSettingController";
import { color } from "react-native-reanimated";

const theme = createTheme({
    palette: {
        primary: {
            main: "#206FC4"
        }
    },
    typography: {
        fontFamily: "Inter, Rubik, Roboto"
    }
});

export default class FreelancerPasswordSetting extends FreelancerPasswordSettingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderDialogBox = () => {
      return (
          <CustomDialog
              className="radius-class"
              open={this.state.isDialogOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
              <DialogContent style={{ textAlign: 'center',padding:"24px" }}>
                  <img src={image_Success} alt="Success" style={{ width: "35%", borderRadius: "50%",marginTop:"20px" ,marginBottom:"10px"}} />
                  <Typography gutterBottom style={styles.congratsText}>
                  Password Changed!
                  </Typography>
                  <Typography style={styles.interStyleText}>
                  Your account password has been changed successfully!
                  </Typography>
              </DialogContent>
          </CustomDialog>
      );
  };
    // Customizable Area End
    render() {  
        return (
            // Customizable Area Start
            // Required for all blocks
            <ThemeProvider theme={theme}>
                <HeaderGrid container>
                    <Grid className="inner-header-box" item xs={12}>
                    <Box style={styles.passwordSettingBorderBox}>
                        <Typography style={styles.advanceText}>Advanced Options</Typography>

                        <Box style={{...styles.headingDisplay,marginTop:"32px" }}>
                        <Typography style={styles.passwordText}>Password</Typography>
                        <Box>
                            <img src={edit} onClick={this.handleOpenPasswordModal}/>
                        </Box>
                        </Box>
                        <Typography style={styles.lastUpdate}>{this.state.apiResupdateMsg}</Typography>
                    </Box>

                    <Box style={{...styles.passwordSettingBorderBox,marginTop:"24px"}}>
                        <Typography style={styles.advanceText}>Two- Step Verification Options</Typography>
                        <Typography style={{...styles.lastUpdate,marginTop:"4px"}}> Add an extra layer of security to block unauthorized access and protect your account.</Typography>
                        <Typography style={{...styles.lastUpdate,marginTop:"32px"}}>Create up to two different versions of your profile to more effectively highlight individual specialities</Typography>
                       
                        <Box style={styles.headingWithGap2}>
                        <Box style={styles.switchContainer}> 
                            <Box style={styles.flexContainer}>
                            <Typography style={styles.passwordText}>Email Messages</Typography>
                            <img src={Helpcircle} width={20} height={20}/>
                            </Box>
                            <IOSSwitch checked={this.state.toggleEmail} onChange={this.handleChange} name="checkedB" data-test-id="toggleEmail"/>
                        </Box>
                        <Typography style={{...styles.lastUpdate,marginTop:"12px"}}>Receive a six digit code by text message to confirm it’s you.</Typography>
                        </Box>
                    </Box> 
                    </Grid>
                </HeaderGrid>
                
                <PasswordDialog open={this.state.openPasswordModal} onClose={this.handleClosePasswordModal}
                 maxWidth="sm" fullWidth className="radius-class" >

                <DialogContent style={{padding:"24px"}}>
                <div style={{...styles.headingDisplay,alignItems:"center",borderBottom:"1px solid #979797"}}>
                <Typography style={{...styles.passwordLable,marginBottom:"16px"}}>Change Password</Typography>
                <img src={cancel} width={14} height={14} style={{marginBottom:"16px",cursor:"pointer"}} onClick={this.handleClosePasswordModal}/>
                </div>
                <div style={{marginTop:"32px"}}>
                    <div style={{marginBottom:"24px"}}>
                    <Typography style={styles.text16bold}>Current Password</Typography>
                    <FormControl style={styles.textField} variant="outlined">
                          <CustomTextField
                               onFocus={this.handleFocus}
                               onBlur={this.handleBlur}
                               error={!!this.state.currentPasswordError}
                               data-test-id="txtInputCurrentPassword"
                            placeholder="Enter your Current password"
                            name="current_password"
                            type="text"
                            variant="outlined"
                            aria-describedby="outlined-weight-helper-text"
                            value={this.state.currentPassword}
                            onChange={this.handleCurrentPasswordChange.bind(this)} 
                            inputProps={{
                              "aria-label": "current_password",
                              style: {
                                fontSize: "16px",
                                fontWeight: 500,
                                fontFamily: "'Inter', sans-serif",
                                color: "#000000"
                              },
                              maxLength: 12
                            }}                           
                          />
                    {this.state.currentPasswordError && <Typography style={styles.errorMsg}>{this.state.currentPasswordError}</Typography>}

                    </FormControl>
                    </div>

                   <div style={{marginBottom:"24px"}}>
                    <Typography style={styles.text16bold}>New Password</Typography>
                    <FormControl style={styles.textField} variant="outlined">
                          <TextField
                           onFocus={this.handleFocus}
                           onBlur={this.handleBlur}
                           error={!!this.state.newPasswordError}
                           data-test-id="txtInputNewPassword"
                            placeholder="Enter your new password"
                            name="new_password"
                            type="text"
                            variant="outlined"
                            aria-describedby="outlined-weight-helper-text"
                            value={this.state.newPassword} 
                            onChange={this.handleNewPasswordChange.bind(this)} 
                            inputProps={{
                              "aria-label": "new_password",
                              style: {
                                fontSize: "16px",
                                fontWeight: 500,
                                fontFamily: "'Inter', sans-serif",
                                color: "#000000"
                              },
                              maxLength: 12
                            }}                           
                          />
                    {this.state.newPasswordError && <Typography style={styles.errorMsg}>{this.state.newPasswordError}</Typography>}

                    </FormControl>
                    </div>

                    <div>
                    <Typography style={styles.text16bold}>Confirm New Password</Typography>
                    <FormControl style={styles.textField} variant="outlined">
                          <TextField
                              onFocus={this.handleFocus}
                              onBlur={this.handleBlur}
                               error={!!this.state.confirmPasswordError}
                              data-test-id="txtInputConfirmPassword"
                            name="confirmNewPassword"
                            type="text"
                            variant="outlined"
                            placeholder="Enter your Confirm new password"
                            aria-describedby="outlined-weight-helper-text"
                            value={this.state.confirmNewPassword}
                            onChange={this.handleConfirmNewPasswordChange.bind(this)}
                            inputProps={{
                              "aria-label": "confirm New Password",
                              style: {
                                fontSize: "16px",
                                fontWeight: 500,
                                fontFamily: "'Inter', sans-serif",
                                color: "#000000"
                              },
                              maxLength: 12
                            }}                           
                          />
                    {this.state.confirmPasswordError  && <Typography style={styles.errorMsg}>{this.state.confirmPasswordError}</Typography>}

                    </FormControl>
                    </div>
                </div>
                </DialogContent>

                <DialogActions style={{display:"flex",gap:"12px",padding:"24px"}}>
                    <button onClick={this.handleClosePasswordModal} style={styles.cancel}>
                    Cancel
                    </button>
                    <button style={styles.saveBtn} onClick={this.handleSubmit}>
                    Save
                    </button>
                </DialogActions>
                </PasswordDialog>

                {this.renderDialogBox()}
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const styles = {
  errorMsg:{
color:"#FF0000",
fontFamily:"Rubik",
fontSize: '16px',
fontWeight: 400,



  },
    passwordSettingBorderBox: {
        border:"1px solid #CECECE",
        padding:"24px",
        borderRadius:"16px"
    },
    advanceText:{
        fontSize: '24px',
        fontWeight: 700,
        fontFamily:"Rubik",
    },
    passwordText:{
        fontSize: '20px',
        fontWeight: 500,
        fontFamily:"Rubik",
    },
    lastUpdate:{
        fontSize: '16px',
        fontFamily:"Rubik",
        marginTop: '12px'
    },
    text16bold:{
        fontSize: '16px',
        fontFamily:"Rubik",
        fontWeight: 500,
        marginBottom:"12px"
    },
    text14:{
        fontSize: '14px',
        fontFamily:"Rubik",
    },
    headingDisplay: {
        display: "flex",
        justifyContent: "space-between"
    },
    headingWithGap:{
        marginTop:"32px",
        display:"flex",
        gap:"12px",
        alignItems:"center" 
    },
    headingWithGap2: {
        display: 'flex', 
        flexDirection: 'column' as 'column', 
        justifyContent: 'center',
        marginTop:"32px",
      },
      switchContainer: {
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center'
      },
      flexContainer: {
        display: 'flex', 
        alignItems: 'center',
        gap:"12px"
      },
      textField: {
        width: "100%",
        "&.MuiFormLabel-root.Mui-focused ": {
          color: "#6F6E6E !important"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ": {
          borderColor: "#6F6E6E !important"
        }
      },
      cancel:{
        color: "#364BA0",
        border: "1px solid #364BA0",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        padding: "10px 22px",
        fontSize: "16px",
        fontFamily: "Rubik",
        fontWeight: 500,
        background:"#fff",
        cursor:"pointer"
      },
      saveBtn:{
        color: "#fff",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        padding: "10px 22px",
        fontSize: "16px",
        fontFamily: "Rubik",
        fontWeight: 500,
        background:"#364BA0",
        border: "1px solid #364BA0",
        cursor:"pointer"
      },
    passwordLable: {
        fontSize: '24px',
        fontWeight: 500,
        fontFamily: 'Rubik',
    },
    inputPropsStyle: {
        borderRadius: '10px'
    },
    formError: {
        color: "red",
        fontSize: 14,
        fontFamily: "'Inter', sans-serif"
      },
      congratsText:{
        marginTop:"24px",
        fontSize: "24px",
        color: "#059669",
        fontWeight: 600,
        fontFamily: "'Inter', sans-serif"
    },
    interStyleText:{
        marginTop:"24px",
        fontSize: "16px",
        color: "#011342",
        fontFamily: "'Inter', sans-serif"
    },
}
// Customizable Area End
