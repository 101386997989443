import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from "react";

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  isTyping: boolean;
  isPasswordValid: boolean
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  job: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  showJobs: boolean;
  selectedJob: string[];
  country: any;
  showPassword: boolean;
  showCountryDropdown: boolean;
  containsUppercase: boolean;
  containsLowerCase:boolean;
  containsNumber: boolean;
  containsSpecialChar: boolean;
  additionalTextBoxVisible: boolean;
  additionalTextBoxValue: string;
  emailError: string;
  firstNameErr: string;
  lastNameErr: string;
  passwordErr: string;
  countryErr: string;
  jobErr: string;
  openCountry:boolean;
  countryError:boolean;
  sitekey: any;
  captchaDone:any;
  captchaErr:string;
  isTermsAccepted: boolean
  emailValid: string;
  authToken:string
  emailToken:string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  formikRef: any;
  clientSignUpApiCallID: any;
  apiOtpVerifactionCallId:any;
  inputRef: any;
  apiEmailOtpCallId:any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      isTyping: false,
      isPasswordValid: false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      job: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      showJobs: false,
      selectedJob: [],
      country: "",
      showPassword: false,
      showCountryDropdown: false,
      containsUppercase: false,
      containsLowerCase:false,
      containsNumber: false,
      containsSpecialChar: false,
      additionalTextBoxVisible: false,
      additionalTextBoxValue: "",
      emailError: "",
      firstNameErr: "",
      lastNameErr: "",
      passwordErr: "",
      countryErr: "",
      jobErr: "",
      captchaErr:"",
      openCountry:false,
      countryError:false,
      sitekey: "6LcB2F8pAAAAAOc5aiGNwLr0e3_8ZuqkZLwCDocB",
      captchaDone:"",
      isTermsAccepted: false,
      emailValid: "",
      authToken:"",
      emailToken:""
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");
    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;
    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.errors) {
        const errorMessage = responseJson.errors[0]?.account;
        if (errorMessage) {
          this.setState({
            emailError: errorMessage,
          });
          return;
        }
      }
      
      if (apiRequestCallId === this.clientSignUpApiCallID) {
        {
          this.handleClientSignUpApi(responseJson);
        }
      }  if (apiRequestCallId === this.apiEmailOtpCallId) {
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          this.setState({ emailToken: responseJson.meta.token }, () => {
            this.handleNavigateToActivityFeed();
          });
        } 
      }
      
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
   
    // Customizable Area End
  }

  // Customizable Area Start
  validateForm = () => {
    const { 
      email, 
      firstName, 
      lastName, 
      password, 
      country, 
      containsUppercase,
      containsNumber,
      containsSpecialChar,
      containsLowerCase 
    } = this.state;
    
    let isValid = true;
  
    if (!email) {
      this.setState({ emailError: "* Email is required" });
      isValid = false;
    } else {
      this.setState({ emailError: "" });
    }
  
    if (!firstName) {
      this.setState({ firstNameErr: "* First name is required" });
      isValid = false;
    } else if (firstName.length < 2 || lastName.length > 36) {
      this.setState({ firstNameErr: "* First name should be between 2 and 36 characters" });
      isValid = false;
    } else {
      this.setState({ firstNameErr: "" });
    }
  
    if (!lastName) {
      this.setState({ lastNameErr: "* Last name is required" });
      isValid = false;
    } else if (lastName.length < 2 || lastName.length > 36) {
      this.setState({ lastNameErr: "* Last name should be between 2 and 36 characters" });
      isValid = false;
    } else {
      this.setState({ lastNameErr: "" });
    }    
  
    if (!password) {
      this.setState({ passwordErr: configJSON.requiredPassword });
      isValid = false;
    } else if (
      !containsUppercase ||
      !containsNumber ||
      !containsSpecialChar ||
      !containsLowerCase
    ) {
      this.setState({ passwordErr: configJSON.passwordRequirements });
      isValid = false;
    } else {
      this.setState({ passwordErr: "" });
    }
  
    if (!country) {
      this.setState({ countryErr: "* Country is required" });
      isValid = false;
    } else {
      this.setState({ countryErr: "" });
    }
    return isValid;
  };

  handleClientSignUp = () => {
    if (this.validateForm()) {
      this.setState({
        emailError: "",
        firstNameErr: "",
        lastNameErr: "",
        passwordErr: "",
        countryErr: "",
      });
  
      this.ClientSignUpApi();
    }
  };
  ClientSignUpApi = () => {
    const header = {
      redirect: 'follow'
    };

    let formdata = new FormData();
    formdata.append("[data][attributes][email]", this.state.email);
    formdata.append("[data][attributes][first_name]", this.state.firstName);
    formdata.append("[data][attributes][last_name]", this.state.lastName);
    formdata.append("[data][attributes][password]", this.state.password);
    formdata.append("[data][attributes][role_id]", "customer");
    formdata.append("[data][attributes][country]", this.state.country.value);
    formdata.append("[data][type]","email_account")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.clientSignUpApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PostSignUpEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.post
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }
  
  handleClientSignUpApi = (responseJson: any) => {
    if (responseJson.errors) {
      const emailErrorMessage = responseJson.errors[0]?.account;
      if (emailErrorMessage) {
        this.setState({ emailError: emailErrorMessage }); 
      }
    } else {
      this.emailOtp(); 
    }
  }
  

   emailOtp = () => {
    const header = {
      "Content-Type": "application/json"
    };
  
    let emailData = {
      "data": {
        "attributes": {
          "email": this.state.email
        }
      }
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PostEmailOtpEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(emailData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.post
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

   handleNavigateToActivityFeed = async() =>{
    const message1: Message = new Message(getName(MessageEnum.NavigationMessage))
    message1.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'OTPVerification'
    );
    await setStorageData("clientEmail",this.state.email)
    await setStorageData("clientToken",this.state.emailToken)
    message1.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage1: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage1.addData(getName(MessageEnum.SessionResponseData), { token:this.state.emailToken,email:this.state.email})
    message1.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage1);
    this.send(message1);
  }


  checkPasswordStrength = (password: string) => {
    const containsLowerCase = /[a-z]/.test(password);
    const containsUppercase = /[A-Z]/.test(password);
    const containsNumber = /\d/.test(password);
    const containsSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);
  
    this.setState({
      containsLowerCase,
      containsUppercase,
      containsNumber,
      containsSpecialChar
    });
  };

  handleCountry = (countries:any) => {
    this.setState({country:countries,countryErr:""})
  }

  handleSelectMainBoxClick = (type: "job" | "country") => {
    this.setState(prevState => ({
      showJobs: type === "job" ? !prevState.showJobs : prevState.showJobs,
      showCountryDropdown:
        type === "country"
          ? !prevState.showCountryDropdown
          : prevState.showCountryDropdown
    }));
  };

  handleClickAway = () => {
    this.setState({ showJobs: false});
  }
  handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    this.setState({ country: event.target.value as string, countryErr: "" });
  };
 
  handleJobSelection = (jobTitle: string) => {
    const { selectedJob } = this.state;
    const updatedSelectedJob = [...selectedJob];
    const index = updatedSelectedJob.indexOf(jobTitle);

    if (index === -1) {
      updatedSelectedJob.push(jobTitle);
    } else {
      updatedSelectedJob.splice(index, 1);
    }
    const additionalTextBoxVisible = updatedSelectedJob.includes("Others");
    this.setState({
      selectedJob: updatedSelectedJob,
      additionalTextBoxVisible,
      jobErr: ""
    });
  };

  handleRemoveSelectedJob = (jobTitleToRemove: string) => {
    const { selectedJob } = this.state;
    const updatedSelectedJob = selectedJob.filter(
      (item: any) => item !== jobTitleToRemove
    );
    this.setState({ selectedJob: updatedSelectedJob });
  };

  handleFirstNameChange = (event: any) => {
    const { value } = event.target;
    const sanitizedValue = value.replace(/^\s*/, '');
    this.setState({ 
      firstName: sanitizedValue, 
      firstNameErr: "" 
    });
  };
  handleEmailNameChange = (event: any) => {
    const { value } = event.target;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
    const sanitizedValue = value.replace(/^\s*/, '');
  
    if (!sanitizedValue.match(emailPattern)) {
      this.setState({
        emailError: "* Please enter a valid email address",
        email: sanitizedValue
      });
    } else {
      this.setState({
        emailError: "",
        email: sanitizedValue
      });
    }
  };
  
  handleLastNameChange = (event: any) => {
  const { value } = event.target;
  const sanitizedValue = value.replace(/^\s*/, '');
  this.setState({ 
    lastName: sanitizedValue, 
    lastNameErr: "" 
  });
};
  
  handleSignIn = () => {
    setStorageData('role','client')
    this.props.navigation.navigate("OTPVerification");
  };
  handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  handleTermsCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ isTermsAccepted: event.target.checked });
  };
  handleCountryDropdownClick = () => {
    this.setState(prevState => ({
      showCountryDropdown: !prevState.showCountryDropdown 
    }));
  };
    passwordErrorMessage = "* Please enter password";
    passwordErrorMessage3= "* Password must not exceed 12 characters"
    passwordErrorMessage5= "* Password must be at least 8 characters long"

    passwordErrorMessage4 = "* Password must not contain spaces"
    handleChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newPassword = event.target.value;
    
      if (!newPassword) {
        this.setState({ passwordErr: this.passwordErrorMessage });
      
      }
      else if (newPassword.length <8) {
        this.setState({
          passwordErr: this.passwordErrorMessage5
        });
      }
       else if (newPassword.length > 12) {
        this.setState({
          passwordErr: this.passwordErrorMessage3
        });
      } else if (newPassword.includes(" ")) {
        this.setState({
          passwordErr: this.passwordErrorMessage4
        });
      }  else {
        this.setState({ passwordErr: "" });
      }
    
      this.checkPasswordStrength(newPassword);
      this.setState({ password: newPassword });
    };

  
    handleLogoClick = ()=>{
      this.props.navigation.navigate("LandingPage");
    }
    
  togglePasswordVisibility = () => {
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };
  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    this.setState(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };
  handleKeyPress = (event: any) => {
    const charCode = event.charCode;
    if (
      (charCode >= 65 && charCode <= 90) ||   // A-Z
      (charCode >= 97 && charCode <= 122) ||  // a-z
      (charCode >= 48 && charCode <= 57) ||   // 0-9
      charCode === 32                         // space
    ) {
      return true;
    } else {
      event.preventDefault();
    }
  };
  

  handleCaptchaValue = (value: string) => {
    if (value.length === 0) {
      this.setState({ captchaDone: value, captchaErr: "* Please complete the ReCAPTCHA" });
    } else {
      this.setState({ captchaDone: value, captchaErr: "" });
    }
  };
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }
  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnSignUpProps = {
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });
    },
    secureTextEntry: true
  };

  handleFocus=()=> {
    this.setState({ isTyping: true });
  }

  handleBlur=()=> {
    this.setState({ isTyping: false });
  }
  // Customizable Area End
}
