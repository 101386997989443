import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import storage from "../../../framework/src/StorageProvider";
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

interface ValidResponseType

{
  message: object;
  data: object;
}

interface InvalidResponseType
 {
  errors: Array<string>;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  isClient?: boolean;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  showOldPassword: boolean;
  oldPassword: string;
  showNewPassword: boolean;
  newPassword: string;
  showConfirmPassword: boolean;
  confirmPassword: string;
  confirmValidation: boolean;
  errorMessage: string;
  upperCaseCheck:boolean;
  numberCheck:boolean;
  specialCharCheck:boolean;
  requiredError:boolean;
  passwordErr: string;
  oldPasswordError:string;
  currentPassword:string;
  alertSamePopUp:boolean;
  alertSuccessPopUp:boolean;
  toggleEmail:boolean;
  openPasswordModal: boolean;
  isDialogOpen:boolean;
  confirmNewPassword:string;
  authToken:string;
  apiRasStatus:boolean;
  apiResupdateMsg:string
  currentPasswordError: string,
  newPasswordError: string,
  confirmPasswordError: string,
  isTyping: boolean,
    // Customizable Area End
}
interface SS { }

export default class FreelancerPasswordSettingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  forgatePasswordAPICallID:string='';
  upDatePasswordID: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.forgatePasswordAPICallID='';
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      authToken:"",  
      showOldPassword:false,
      oldPassword:'',
      showNewPassword: false,
      newPassword:'',
      showConfirmPassword: false,
      confirmPassword:'',
      confirmValidation:false,
      errorMessage:'',
      upperCaseCheck:false,
      numberCheck:false,
      specialCharCheck:false,
      requiredError:false,
      passwordErr:"",
      oldPasswordError:"",
      currentPassword:"",
      alertSamePopUp:false,
      alertSuccessPopUp:false,
      toggleEmail:true,
      openPasswordModal: false,
      isDialogOpen:false,
      confirmNewPassword: '',
      apiRasStatus:false,
      apiResupdateMsg:"Last updated 0 days ago",
      currentPasswordError: '',
      newPasswordError: '',
      confirmPasswordError: '',
      isTyping: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.setState({
      authToken: await storage.get('authToken'),
    })
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start

    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
 
    runEngine.debugLog('Message Recived', message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.upDatePasswordID) {
        this.validation(responseJson)
        this.setState({
        apiRasStatus:responseJson.status,
        apiResupdateMsg:responseJson.last_password_updated_at
        })
        if(responseJson.status){
          this.handleClosePasswordModal();
          this.handleOpenDialog()
          }
      }
  
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleChange = (event:any) => {
    this.setState({ toggleEmail: event.target.checked });
  };
  
  handleOpenPasswordModal = () => {
    this.setState({ openPasswordModal: true });
  };

  handleClosePasswordModal = () => {
    this.setState({ openPasswordModal: false });
  };

  handleOpenDialog = () => {
    this.setState({ isDialogOpen: true, openPasswordModal: false  });
    setTimeout(() => {
      this.setState({ isDialogOpen: false });
    }, 3000);
  };

   handleCurrentPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ currentPassword: event.target.value, currentPasswordError: '' });
  };
  handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    if (newPassword.trim() === "") {
      this.setState({ newPasswordError: "New password is required" });
    } else {
      this.setState({ newPasswordError: '' });
    }
    this.setState({ newPassword });
  };
  handleConfirmNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ confirmNewPassword: event.target.value, confirmPasswordError: '' });
  };

  handleSubmit = () => {
    const { currentPassword, newPassword, confirmNewPassword } = this.state;
  let hasError = false;

  if (currentPassword.trim() === "") {
      this.setState({ currentPasswordError: "Current password is required" });
      hasError = true;
  } else {
      this.setState({ currentPasswordError: '' });
  }

  if (newPassword.trim() === "") {
      this.setState({ newPasswordError: "New password is required" });
      hasError = true;
  } else {
      this.setState({ newPasswordError: '' });
  }

  if (confirmNewPassword.trim() === "") {
      this.setState({ confirmPasswordError: "Please confirm your new password" });
      hasError = true;
  } else {
      this.setState({ confirmPasswordError: '' });
  }

  if (newPassword.length < 8) {
      this.setState({ newPasswordError: "Password must be at least 8 characters long" });
      hasError = true;
  } else if (newPassword.length > 12) {
      this.setState({ newPasswordError: "Password must not exceed 12 characters" });
      hasError = true;
  } else if (!/[A-Z]/.test(newPassword)) {
      this.setState({ newPasswordError: "Password must contain at least one uppercase letter" });
      hasError = true;
  } else if (!/[0-9]/.test(newPassword)) {
      this.setState({ newPasswordError: "Password must contain at least one number" });
      hasError = true;
  } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
      this.setState({ newPasswordError: "Password must contain at least one special character" });
      hasError = true;
  }

  if (newPassword !== confirmNewPassword) {
      this.setState({ confirmPasswordError: "New password and confirmation do not match" });
      hasError = true;
  }
    if (!hasError) {
      this.handlePasswordChnagesApi();
  }
    
    
  };
  validation(response: { status: boolean; error?: string; }) {
    if (!response.status) {
        const errorMessage = response.error || "An unknown error occurred.";
        if (errorMessage === "Old password is not correct.") {
            this.setState({ currentPasswordError: errorMessage });
        } else if (errorMessage === "New and confirm password do not match.") {
            this.setState({ confirmPasswordError: errorMessage });
        } else {
            this.setState({ errorMessage: errorMessage });
        }
    } else {
        console.log("Password changed successfully");
        this.handleClosePasswordModal();
    }
}
handleFocus=()=> {
  this.setState({ isTyping: true });
}

handleBlur=()=> {
  this.setState({ isTyping: false });
}

  handlePasswordChnagesApi = () => {
    const header = {
      "Content-Type": "application/json",
      "token": this.state.authToken
    };
    let body ={
      "old_password": this.state.currentPassword,
      "new_password": this.state.newPassword,
      "password_confirmation": this.state.confirmNewPassword,
  };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.upDatePasswordID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/freelancers/update_password_freelencer`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body) 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  };
  // Customizable Area End
}
