export const avtara = require("../assets/avtara.svg");
export const editIcon = require("../assets/editIcon.svg");
export const locationIcon = require("../assets/locationIcon.png");
export const unCheckIcon = require("../assets/unCheckIcon.png");
export const checkIcon = require("../assets/check.png");
export const roundCheckIcon = require("../assets/roundCheckIcon.png");
export const profileSettingImg = require("../assets/profileSettingImg.svg");
export const add = require("../assets/add.png");
export const edit = require("../assets/edit.png");
export const facebook = require("../assets/facebook.png");
export const medium = require("../assets/medium.png");
export const twitter = require("../assets/twitter.png");
export const tick = require("../assets/Tick.png");
export const Helpcircle = require("../assets/Helpcircle.png");
export const projectPic = require("../assets/projectPic.png");
export const cancel = require("../assets/cancel.png");
export const connectedBtn = require("../assets/connectedBtn.png");
export const paypal = require("../assets/paypal.png");
export const deleteBtn = require("../assets/delete.png");

export const outlineEdit = require("../assets/outlineEdit.png");
export const online = require("../assets/online.png");
export const PaymentCard = require("../assets/Payment card.png");


