import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  ratingNumber:number | null;
  rating: string
  selectedTab: number, 
  projects: any[]
  isPublicView: boolean
  // Customizable Area End
}
interface SS { }

export default class FreelancerCompleteProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Star
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      ratingNumber: 5,
      rating: "4.95/5",
      selectedTab: 0, 
      projects: [
        {
            title: "XYZ Project",
            duration: "Mar 6, 2020 - Oct 12, 2020",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
        },
        {
            title: "ABC Project",
            duration: "Jan 1, 2021 - Jul 15, 2021",
            description: "Another project description, with its own details...",
        },
      ],
      isPublicView: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
  
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleTabChange = (event: any, newValue: number) => {
    this.setState({ selectedTab: newValue });
  };
  
  toggleView = () => {
    this.setState((prevState) => ({
        isPublicView: !prevState.isPublicView,
    }));
};
  // Customizable Area End
}
