export const second_steper = require("../assets/second_steper.png");
export const thirdStep = require("../assets/thirdStep.png");
export const AvatarImg = require('../assets/Group.png');
export const firstStep = require('../assets/firstStep.png');
export const addBtn = require('../assets/addBtn.png')
export const forthstep = require('../assets/forthstep.png')
export const image_Success = require("../assets/image_Success.png");
export const remind_icon = require("../assets/remind_icon.png");
export const fifth_steper = require('../assets/fifth_steper.png');
export const step_1_active = require('../assets/step_1_active.png');
export const step_2_active = require('../assets/step_2_active.png');
export const step_3_active = require('../assets/step_3_active.png');
export const step_4_active = require('../assets/step_4_active.png');
export const step_5_active = require('../assets/step_5_active.png');
export const step_1 = require('../assets/step_1.png');
export const step_2 = require('../assets/step_2.png');
export const step_3 = require('../assets/step_3.png');
export const step_4 = require('../assets/step_4.png');
export const step_5 = require('../assets/step_5.png');
export const step_22 = require('../assets/step_22.png');
export const step_33 = require('../assets/step_33.png');
export const step_44 = require('../assets/step_44.png');
export const twitter = require('../assets/twitter.png');
export const facebook = require('../assets/facebook.png');
export const medium = require('../assets/medium.png');
export const linkedin = require('../assets/linkedin.png');
export const instagram = require('../assets/instagram.png');
export const youtube = require('../assets/youtube.png');






