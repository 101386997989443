import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  storeData:string;
  profileDetails:{
    name:string,
    component:string
  }[]
  // Customizable Area End
}
interface SS { }

export default class ProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiEditDetailCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      storeData:'personalDetails',
      profileDetails:[
        {
            name:'My Profile',
            component:'myProfile'
        },
        {
            name:'Profile Settings',
            component:'profileSettings'
        },
        {
            name:'Password and Security',
            component:'passwordSecurity'
        },
        {
            name:'Contact Information',
            component:'contactInformation'
        },
        {
          name:'Billing Information',
          component:'billingInformation'
      },
      ]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start

    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start 

  handleSelectComponent = (data:string) => {
    this.setState({storeData:data})
  }
  // Customizable Area End
}