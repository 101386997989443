import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  Link,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
export const config = require("./config");
import { authImage } from "./assets";
import { Horizontalinventohub } from "../../landingpage/src/assets";

const MainContainer = styled(Box)({
  paddingInline: 15,
  '@media(max-width: 959px)': {
    '& .dots-img': {
      display: 'none',
    },
    '& .firstContainer': {
      padding: "20px 10px", 
      margin:"auto"
    },
    '& .authImage': {
      display: "none", 
    }
  },
  '@media(min-width: 960px)': {
    '& .firstContainer': {
      padding: "4% 9%",
    },
  }
});
// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#206FC4"
    }
  },
  typography: {
    fontFamily: "Inter, Rubik, Roboto"
  }
});

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderResponseDialog = () => {
    return (
      <Dialog
        open={this.state.popUpMessage}
        onClose={this.handleDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={styles.dialogOuterContainer}
        data-testid="response-modal"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.state.mailNotification}
          </DialogContentText>

          <DialogActions style={styles.btnOuterContainer}>
            <Button
              onClick={this.handleDialog}
              data-testid="ok-btn"
              color="primary"
              variant="contained"
            >
              {config.okBtnText}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <MainContainer>
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid item xs={12} sm={6} className="firstContainer">
            <img src={Horizontalinventohub} style={styles.invento as React.CSSProperties} onClick={this.handleLogoClick} data-test-id="handleLogoClick"/>

            <Box style={{marginBottom:"68px"}}>
            <Typography style={styles.forgotPassword}>
              {config.forgotPassowrd}
            </Typography>
            <Typography style={styles.details}>
              {config.emailEnterDetails}
            </Typography>
            </Box>

            <Box style={{marginBottom:"51px"}}>
              <Typography style={styles.emailLabel}>
                {config.emailLabel}
              </Typography>
              <TextField
                variant="outlined"
                value={this.state.emailId}
                placeholder={config.emailPlaceholder}
                onChange={this.handleEmailChange}
                fullWidth
                InputProps={{
                  style: styles.inputPropsStyle
                }}
                data-testid="email-id"
                error={this.state.validationError || this.state.requireError}
                helperText={this.state.errorMessage}
              />
            </Box>
            <Button
              variant="contained"
              style={styles.buttonContainer}
              onClick={this.handleSubmit}
              data-testid="submit-btn"
            >
              {config.restButtonText}
            </Button>
            <Typography style={styles.backToText}>
              Back to
              <span>
                <Link
                  underline="always"
                  style={styles.loginText}
                  onClick={this.handleBackToLogin}
                  data-testid="login-btn"
                >
                  SIGN IN
                </Link>
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} className="dots-img">
            <img
              src={authImage}
              alt="forgot-password"
              style={styles.authImage}
              className="authImage"
            />
          </Grid>
        </Grid>
        {this.renderResponseDialog()}
      </ThemeProvider>
      </MainContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  firstContainer: {
    padding: "4% 9%",
  },
  invento: {
    width: "264px",
    height: "48px",
    objectFit: "cover",
    marginBottom: "115px",
  },
  forgotPassword: {
    fontSize: "32px",
    color: "#364BA0",
    fontWeight: 500,
    fontFamily: "Rubik",
    marginBottom: "9px",
    
  },
  details: {
    fontSize: "18px",
    lineHeight: "21.78px",
    fontFamily: "Rubik",
    color: "#000000",
   
  },
  emailLabel: {
    fontSize: "20px",
    color: "#535353",
    fontWeight: 500,
    fontFamily: "Rubik",
    marginBottom: "12px",
  },
  inputPropsStyle: {
    borderRadius: "10px",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 500,
    color: "#000000",
  },
  buttonContainer: {
    borderRadius: "10px",
    background: "#364BA0",
    width: "100%",
    padding: "16px",
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Rubik",
    color: "#ffffff",
    border: "1px solid #364BA0",
    marginBottom: "40px",
  },
  backToText: {
    fontSize: "20px",
    fontFamily: "Rubik",
    color: "#6F6E6E",
    fontWeight: 500,
  },
  loginText: {
    fontSize: "20px",
    color:"#364BA0",
    fontFamily: "Rubik",
    fontWeight: 600,
    marginLeft: "9px",
    cursor: "pointer",
  },
  authImage: {
    width: "100%",
    height: "100%",
  },
  dialogOuterContainer: {
    boxShadow: "none",
  },
  btnOuterContainer: {
    display: "flex",
    justifyContent: "center",
  }
};
// Customizable Area End
