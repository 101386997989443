import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  projectType:string;
  preference:string;
  selectedLevel: string;
  areaOfExpertise: string[];
  industryFocus: string[];
  // Customizable Area End
}
interface SS { }

export default class FreelancerProfileSettingsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        projectType:'',
        preference:"",
        selectedLevel: "expert",
        areaOfExpertise: ['IP Licensing', 'Industrial Design', 'IP Valuation'],
        industryFocus: ['Technology', 'Media', 'Consumer Electronics', 'Automotive'],

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start

    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleSelectChange = (event: any) => {
    this.setState({ preference: event.target.value as string });
  };

  handleLevelChange = (level: string) => {
    this.setState({ selectedLevel: level });
  };
  // Customizable Area End
}
